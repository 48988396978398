import { SearchIcon } from '@ngg/icons';
import debounce from 'lodash.debounce';
import { useSearchParams } from 'next/navigation';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';

import { cn } from '@/lib/utils';
import { useSearchStore } from '@/stores/searchStore';

import { useSearchContext } from './Search.hooks';
import SearchSubmit from './SearchSubmit';

type SearchInputProps = {
    className?: string;
};

const SearchInput = ({ className }: SearchInputProps) => {
    const { dictionary, inputRef } = useSearchContext();
    const params = useSearchParams();
    const [searchValue, setSearchValue] = useState(params.get('query') || '');
    const setSearchQuery = useSearchStore((state) => state.setSearchQuery);

    const debouncedSetSearchQuery = useMemo(
        () => debounce(setSearchQuery, 300),
        [setSearchQuery],
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debouncedSetSearchQuery(event.target.value);
    };

    return (
        <label className="relative grid">
            <input
                ref={inputRef}
                className={cn(
                    'col-span-full row-span-full min-w-60 rounded-none border-b !pl-10',
                    className,
                )}
                name="query"
                placeholder={dictionary?.searchTerm}
                data-testid="search-input"
                value={searchValue}
                onChange={handleChange}
            />
            <SearchSubmit className="pointer-events-none z-10 col-span-full row-span-full ml-3 self-center justify-self-start lg:ml-2">
                <SearchIcon className="h-6 w-6" />
                <span className="sr-only">{dictionary?.submit}</span>
            </SearchSubmit>
        </label>
    );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
