'use client';

import Link from '@components/Link';
import { useHeaderContext } from '@context/headerContext';
import { usePathname } from 'next/navigation';
import { useRef } from 'react';

import { cn } from '@/lib/utils';
import { useMenuStore } from '@/stores/menuStore';

import type { NavigationLink } from '../types';
import Dropdown from './Dropdown';

const MOUSE_ENTER_DELAY_IN_MILLISECONDS = 100;
const MOUSE_LEAVE_DELAY_IN_MILLISECONDS = 200;

export default function Menu() {
    const { locale, navigation } = useHeaderContext();
    const pathname = usePathname();
    const { activeLink, setActiveLink, clearActiveWithDelay } = useMenuStore();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const onMouseEnter = (link: NavigationLink) => {
        if (!link?.children?.length) return;
        clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            setActiveLink(link);
        }, MOUSE_ENTER_DELAY_IN_MILLISECONDS);
    };

    const onMouseLeave = () => {
        clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            setActiveLink();
        }, MOUSE_LEAVE_DELAY_IN_MILLISECONDS);
    };

    return (
        <nav className="-translate-y-[--header-menu-top] border-b border-grey-150 bg-white text-sm transition-transform delay-75 duration-150 max-lg:hidden">
            <ul className="flex flex-col lg:mx-auto lg:w-full lg:max-w-screen-2xl lg:flex-row lg:items-center lg:justify-center">
                {navigation.map((link) => (
                    <li
                        key={link?.title}
                        className={cn(link?.type === 'Simple' && 'relative')}
                        onMouseEnter={() => onMouseEnter(link)}
                        onMouseLeave={onMouseLeave}>
                        <Link
                            className={cn(
                                'flex w-full justify-between whitespace-nowrap px-4 py-2.5 text-sm hover:underline md:pointer-events-auto lg:w-auto lg:px-3 lg:py-1.5 lg:uppercase lg:tracking-wide',
                                pathname.endsWith(link?.slug || '') &&
                                    'underline',
                            )}
                            locale={locale}
                            onClick={() => clearActiveWithDelay()}
                            href={link?.slug}
                            prefetch={false}
                            customPrefetch="onMouseEnter">
                            {link?.title}
                        </Link>
                        {activeLink === link && (
                            <Dropdown locale={locale} activeLink={activeLink} />
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}
