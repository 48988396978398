import { MarketSelectorList } from '@components/MarketSelector';
import { MobileMenuContext } from '@context/mobileMenuContext';
import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';

import MobileMenuDirectionAnimation from './MobileMenuDirectionAnimation';
import MobileMenuLink from './MobileMenuLink';
import MobileMenuTitle from './MobileMenuTitle';

export default function MobileMenuLinks() {
    const { navigationTree } = useContext(MobileMenuContext);

    const currentNavigation = navigationTree?.at(-1);
    if (!currentNavigation) return null;

    if (currentNavigation.type === 'Market') {
        return (
            <div>
                <AnimatePresence initial={false} mode="popLayout">
                    <MobileMenuDirectionAnimation>
                        <MarketSelectorList />
                    </MobileMenuDirectionAnimation>
                </AnimatePresence>
            </div>
        );
    }

    if (!currentNavigation?.children) return null;

    if (currentNavigation.type === 'Brand') {
        return (
            <div className="flex flex-col">
                <AnimatePresence initial={false} mode="popLayout">
                    {currentNavigation.children.map((link, linkIndex) => (
                        <MobileMenuDirectionAnimation
                            key={'brand' + link?.slug + linkIndex}
                            className="border-b last:border-b-0">
                            <MobileMenuTitle
                                link={link}
                                showAllTitle={link?.specialTitle ?? ''}
                            />
                            <div className="py-4">
                                {link?.children
                                    ?.slice(0, 12)
                                    .map((sublink, subLinkIndex) => (
                                        <MobileMenuLink
                                            key={
                                                'sublink' +
                                                sublink?.slug +
                                                subLinkIndex
                                            }
                                            link={sublink ?? undefined}
                                        />
                                    ))}
                            </div>
                        </MobileMenuDirectionAnimation>
                    ))}
                </AnimatePresence>
            </div>
        );
    }

    return (
        <div className="flex flex-col py-2">
            <AnimatePresence initial={false} mode="popLayout">
                {currentNavigation.children?.map((link, linkIndex) => (
                    <MobileMenuDirectionAnimation
                        key={'default' + link?.slug + linkIndex}>
                        <MobileMenuLink link={link} />
                    </MobileMenuDirectionAnimation>
                ))}
            </AnimatePresence>
        </div>
    );
}
