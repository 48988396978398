import { getMarketConfigFromLocale } from '@/config/market-configurations';

export interface TrustpilotConfig {
    locale: string;
    businessunitId: string;
    href: string;
}

export default function hasTrustpilot(locale: string): boolean {
    const marketConfig = getMarketConfigFromLocale(locale);
    return marketConfig.hasTrustPilot;
}
