import Link from '@components/Link/Link';
import { ArrowIcon } from '@ngg/icons';

import { useMenuStore } from '@/stores/menuStore';

import type { NavigationLink } from '../types';
import DropdownHighlight from './DropdownHighlight';

export default function CategoryDropdown({
    link,
    locale,
}: {
    link: NavigationLink;
    locale?: string;
}) {
    const { clearActiveWithDelay } = useMenuStore();

    if (!link) return null;

    return (
        <>
            <ul className="col-span-2 flex flex-col p-2 py-6 space-y-1">
                <li className="font-medium">
                    {link.specialTitle ?? link.title}
                </li>
                {link.special?.map((special) => (
                    <li key={special?.slug}>
                        <Link
                            onClick={() => clearActiveWithDelay()}
                            className="group flex items-center hover:underline"
                            locale={locale}
                            href={special?.slug}
                            prefetch={false}
                            customPrefetch="onMouseEnter">
                            {special?.title}
                            <ArrowIcon className="text-lg opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100" />
                        </Link>
                    </li>
                ))}
            </ul>
            <ul className="col-span-8 columns-4 border-x border-grey-150 px-2 py-4">
                {link.children?.map(
                    (child1) =>
                        child1 && (
                            <li
                                key={child1.slug}
                                className="lg:break-inside-avoid lg:p-2">
                                <Link
                                    onClick={() => {
                                        (
                                            document.activeElement as HTMLElement
                                        )?.blur();
                                        clearActiveWithDelay();
                                    }}
                                    className="group mb-2 flex items-center font-medium last:mb-0 hover:underline"
                                    locale={locale}
                                    href={child1.slug}
                                    prefetch={false}
                                    customPrefetch="onMouseEnter">
                                    {child1.title}
                                    <ArrowIcon className="text-lg opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100" />
                                </Link>
                                <ul className="flex w-full flex-col bg-white space-y-1">
                                    {child1?.children?.map(
                                        (child2) =>
                                            child2 && (
                                                <li key={child2.slug}>
                                                    <Link
                                                        onClick={() => {
                                                            (
                                                                document.activeElement as HTMLElement
                                                            )?.blur();
                                                            clearActiveWithDelay();
                                                        }}
                                                        className="group flex items-center hover:underline"
                                                        locale={locale}
                                                        href={child2.slug}
                                                        prefetch={false}
                                                        customPrefetch="onMouseEnter">
                                                        {child2.title}
                                                        <ArrowIcon className="text-lg opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100" />
                                                    </Link>
                                                </li>
                                            ),
                                    )}
                                </ul>
                            </li>
                        ),
                )}
            </ul>
            <ul className="col-span-2 flex flex-col p-4">
                {link.highlight?.map((highlight) => (
                    <li key={highlight?.slug}>
                        <DropdownHighlight item={highlight} locale={locale} />
                    </li>
                ))}
            </ul>
        </>
    );
}
