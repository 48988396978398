'use client';

import { useWishlist } from '@app/_context/wishlistContext';
import Badge from '@components/Badge/Badge';
import Link from '@components/Link/Link';
import type { AuthState } from '@context/authContext';
import { useAuth } from '@context/authContext';
import { useCartActions, useCartState } from '@context/cartContext';
import { useHeaderContext } from '@context/headerContext';
import { useSiteInfo } from '@context/siteInfoContext';
import { Button } from '@ngg/components';
import { CartIcon, HeartIcon, UserIcon } from '@ngg/icons';
import { useQuery } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import type { Dictionary } from 'types';

import { envConfig } from '@/config/env';
import { gTagViewCart } from '@/utils/googleTags';

export const LoginButton = ({
    locale,
    className,
    children,
    dictionary,
    redirectOnSuccess = true,
}: {
    locale?: string;
    className?: string;
    children: ReactNode;
    dictionary?: Dictionary;
    redirectOnSuccess?: boolean;
}) => {
    const { myPagesStartPage } = useSiteInfo();
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });
    const { showLogin } = useAuth();

    return data?.user ? (
        <Button
            as={Link}
            locale={locale}
            href={myPagesStartPage?.slug}
            className={className}
            title={dictionary?.myPages}
            aria-label={dictionary?.myPages}>
            {children}
        </Button>
    ) : (
        <Button
            onClick={() => showLogin(redirectOnSuccess)}
            type="button"
            className={className}
            title={dictionary?.login}
            aria-label={dictionary?.login}>
            {children}
        </Button>
    );
};

const Actions = () => {
    const { locale, dictionary } = useHeaderContext();
    const { wishlist } = useSiteInfo();
    const { cart } = useCartState();
    const { showCart } = useCartActions();
    const { wishlistData } = useWishlist();
    const wishlistItemCount = wishlistData?.length ?? 0;

    const numItemsInCart = cart?.items
        ?.filter(
            (item) =>
                item?.partNo !==
                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
        )
        ?.reduce((total, item) => total + (item?.quantity || 0), 0);

    const handleOnClick = () => {
        showCart();
        if (cart) gTagViewCart(cart);
    };

    return (
        <div className="flex items-center">
            {envConfig.NEXT_PUBLIC_NORDICS_FEATURE_FLAG === 'true' ? (
                <>
                    <LoginButton
                        locale={locale}
                        className="hidden items-center p-1 md:flex [&>span]:hidden"
                        dictionary={dictionary}>
                        <UserIcon className="text-xl" />
                    </LoginButton>
                    <Link
                        className="p-1"
                        locale={locale}
                        href={wishlist?.slug}
                        title={dictionary?.wishlist}
                        aria-label={dictionary?.wishlist}>
                        <Badge badgeContent={wishlistItemCount}>
                            <HeartIcon className="text-xl" />
                        </Badge>
                    </Link>
                </>
            ) : null}
            <button
                className="p-1"
                type="button"
                onClick={handleOnClick}
                data-testid="cart-icon-button"
                title={dictionary?.yourCart}
                aria-label={dictionary?.yourCart}>
                <Badge badgeContent={numItemsInCart}>
                    <CartIcon className="text-xl" />
                </Badge>
            </button>
        </div>
    );
};

export default Actions;
