type TrustpilotConfig = {
    dataLocale: string;
    businessunitId: string;
    href: string;
};

type LocaleSettings = Record<string, TrustpilotConfig>;

const TRUSTPILOT_SETTINGS: LocaleSettings = {
    sv: {
        dataLocale: 'sv-SE',
        businessunitId: '5c92c1a87a437100018d1da0',
        href: 'https://se.trustpilot.com/review/nordiskagalleriet.se',
    },
    no: {
        dataLocale: 'nb-NO',
        businessunitId: '6016e8ce12060c0001e9b716',
        href: 'https://no.trustpilot.com/review/nordiskagalleriet.no',
    },
    dk: {
        dataLocale: 'da-DK',
        businessunitId: '6016e8d744d371000130797d',
        href: 'https://dk.trustpilot.com/review/nordiskagalleriet.dk',
    },
    fi: {
        dataLocale: 'fi-FI',
        businessunitId: '6016e8d32bc7cf00018d63bc',
        href: 'https://fi.trustpilot.com/review/nordiskagalleriet.fi',
    },
};

export function getTrustpilotConfig(
    contentfulLocale: string,
): TrustpilotConfig | null {
    const config = Object.values(TRUSTPILOT_SETTINGS).find(
        (setting) => setting.dataLocale === contentfulLocale,
    );

    return config || null;
}
