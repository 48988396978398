'use client';

import { useMarket } from '@app/_context';
import Link from '@components/Link';
import { MarketSelectorButton } from '@components/MarketSelector';
import type { AuthState } from '@context/authContext';
import { useAuth } from '@context/authContext';
import { useHeaderContext } from '@context/headerContext';
import { useSiteInfo } from '@context/siteInfoContext';
import { Button, Divider } from '@ngg/components';
import { ArrowIcon, ArrowRightIcon, UserIcon } from '@ngg/icons';
import { useQuery } from '@tanstack/react-query';

import { envConfig } from '@/config/env';
import { FEATURE_FLAGS } from '@/config/featureFlags';
import { useMenuStore } from '@/stores/menuStore';
import { getOpeningHoursText } from '@/utils/common';

const ContactLinks = () => {
    const { locale, contactNavigation: links } = useHeaderContext();
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    if (!links.length) {
        return null;
    }

    return (
        <div className="pt-4 space-y-3">
            {links.map((link) => (
                <p key={link?.slug} className="text-sm font-normal">
                    <Link
                        locale={locale}
                        href={link?.slug}
                        onClick={() => setMobileMenuOpen(false)}>
                        {link?.title}
                    </Link>
                </p>
            ))}
        </div>
    );
};

const CustomerServiceInfo = () => {
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    const { services, dictionary, locale } = useHeaderContext();
    const { contentfulLocale } = useMarket().state.market;
    const customerService = services?.at(0);
    if (!customerService) return null;

    const openingHours = getOpeningHoursText({
        contentfulLocale,
        store: customerService,
        openTodayAt: dictionary?.openTodayAt,
        opensTomorrowAt: dictionary?.opensTomorrowAt,
        opensAtDayOfWeekAt: dictionary?.opensAtDayOfWeekAt,
    });

    return (
        <div className="pt-4">
            <p className="mb-2 text-xxs font-medium uppercase">
                {dictionary?.contactUs}
            </p>
            {FEATURE_FLAGS.customerService.phoneLink && (
                <p>
                    {dictionary?.callUsAt}
                    <a
                        className="ml-1 underline"
                        href={`tel:${customerService.phoneNumber?.replace(
                            /[-\s]/g,
                            '',
                        )}`}>
                        {customerService.phoneNumber}
                    </a>
                </p>
            )}
            {FEATURE_FLAGS.customerService.emailLink && (
                <p>
                    {dictionary?.emailUsAt}
                    <a
                        href={`mailto:${customerService.email}`}
                        className="ml-1 underline">
                        {customerService?.email}
                    </a>
                </p>
            )}

            <p>{openingHours}</p>
            <p>
                <Link
                    locale={locale}
                    className="underline"
                    href={customerService.storePage?.slug}
                    onClick={() => setMobileMenuOpen(false)}>
                    {customerService.storePageLinkText}
                </Link>
                <ArrowIcon className="mb-1 inline -rotate-45 text-xl" />
            </p>
        </div>
    );
};

const StoreInfo = () => {
    const { locale, stores, dictionary } = useHeaderContext();
    const { contentfulLocale } = useMarket().state.market;
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    if (!stores?.length) return null;

    return (
        <div className="pt-4">
            <p className="mb-2 text-xxs font-medium uppercase">
                {dictionary?.ourStores}
            </p>
            <div className="space-y-4">
                {stores.map((store) => {
                    const openingHours = getOpeningHoursText({
                        contentfulLocale,
                        store,
                        openTodayAt: dictionary?.openTodayAt,
                        opensTomorrowAt: dictionary?.opensTomorrowAt,
                        opensAtDayOfWeekAt: dictionary?.opensAtDayOfWeekAt,
                    });
                    return (
                        <div key={store?.sys?.id}>
                            <p>{`${store?.title}, ${store?.address}`}</p>
                            <p>{openingHours}</p>
                            <Link
                                locale={locale}
                                className="underline"
                                href={store.storePage?.slug}
                                onClick={() => setMobileMenuOpen(false)}>
                                {store?.storePageLinkText}
                                <ArrowIcon className="mb-1 inline -rotate-45 text-xl" />
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

function UserButton() {
    const { myPagesStartPage } = useSiteInfo();
    const { dictionary, locale } = useHeaderContext();
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);
    const { showLogin } = useAuth();
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });

    if (data?.user) {
        return (
            <Button
                as={Link}
                href={myPagesStartPage?.slug || ''}
                locale={locale}
                onClick={() => setMobileMenuOpen(false)}
                className="flex w-full items-center gap-1 bg-inherit px-0 py-4 text-black child:max-w-fit lg:hidden [&>*:last-child]:ml-auto"
                endIcon={<ArrowRightIcon className="text-base" />}
                startIcon={<UserIcon className="text-xl" />}>
                {dictionary?.myPages}
            </Button>
        );
    }

    return (
        <Button
            className="flex w-full items-center gap-1 bg-inherit px-0 py-4 text-black child:max-w-fit lg:hidden [&>*:last-child]:ml-auto"
            onClick={() => {
                showLogin();
                setMobileMenuOpen(false);
            }}
            endIcon={<ArrowRightIcon className="text-base" />}
            startIcon={<UserIcon className="text-xl" />}>
            {dictionary?.login}
        </Button>
    );
}

export default function MobileMenuInfo() {
    return (
        <div className="bg-grey-100 px-4 pb-6 lg:hidden">
            <MarketSelectorButton className="w-full content-start gap-1 py-4" />
            {envConfig.NEXT_PUBLIC_NORDICS_FEATURE_FLAG === 'true' && (
                <>
                    <Divider className="-mx-4 h-px" />
                    <UserButton />
                </>
            )}
            <Divider className="-mx-4 h-px" />
            <ContactLinks />
            <Divider className="-mx-4 mt-4 h-px" />
            <CustomerServiceInfo />
            <StoreInfo />
        </div>
    );
}
