'use client';

import { useHeaderContext } from '@context/headerContext';
import { useAutocomplete } from '@server/hooks';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { ChangeEvent, InputHTMLAttributes } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { Dictionary } from 'types/common';

import { getMarketConfigFromLocale } from '@/config/market-configurations';
import { cn } from '@/lib/utils';
import { useMenuStore } from '@/stores/menuStore';
import { useSearchStore } from '@/stores/searchStore';
import { findAutocompleteMatch } from '@/utils/findAutocompleteMatch';
import { getSearchResultPageUrl } from '@/utils/getSearchAutocompleteUrl';

import { Search } from '../Search';

const getStylizedText = (text = '', query = '') => {
    const highlight = text.replace(
        new RegExp(query, 'i'),
        (match: string) => `***${match}***`,
    );
    const [prefix, match, suffix] = highlight.split('***');
    return (
        <>
            <span className="pointer-events-none">{prefix}</span>
            <span className="pointer-events-none font-bold">{match}</span>
            <span className="pointer-events-none">{suffix}</span>
        </>
    );
};

const HeaderSearch = ({
    dictionary,
    classNames,
    onBlur: blurHandler = () => null,
    suggestions: showSuggestions = true,
}: {
    dictionary?: Dictionary;
    suggestions?: boolean;
    classNames: {
        root?: string;
        input?: string;
        icon?: string;
    };
} & InputHTMLAttributes<HTMLInputElement>) => {
    const { locale } = useHeaderContext();

    const pathname = usePathname();
    const router = useRouter();
    const params = useSearchParams();

    const queryClient = useQueryClient();

    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);
    const setSearchFocus = useSearchStore((state) => state.setSearchFocus);

    const [searchValue, setSearchValue] = useState(params.get('query') || '');

    const searchQuery = useSearchStore((state) => state.searchQuery);
    const setSearchQuery = useSearchStore((state) => state.setSearchQuery);

    const autocomplete = useAutocomplete({ query: searchQuery });

    const debouncedSetSearchQuery = useMemo(
        () => debounce(setSearchQuery, 300),
        [setSearchQuery],
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debouncedSetSearchQuery(event.target.value);
    };

    const onSearch = (str: string) => {
        void queryClient.cancelQueries({
            queryKey: ['autocomplete', searchQuery],
        });

        const match = findAutocompleteMatch({
            query: str,
            data: autocomplete.data,
        });

        setMobileMenuOpen(false);
        setSearchFocus(false);
        setSearchQuery('');

        if (match) {
            router.push(
                getSearchResultPageUrl({ locale, query: match.contentUrl }),
            );
            return;
        }

        router.push(getSearchResultPageUrl({ locale, query: str }));
    };

    useEffect(() => {
        const searchPathName = getMarketConfigFromLocale(locale).searchPathName;
        if (pathname.includes(searchPathName)) {
            setSearchValue(params.get('query') || '');
        } else {
            setSearchValue('');
        }

        setSearchFocus(false);
    }, [params, pathname, locale, setSearchValue, setSearchFocus]);

    return (
        <Search.Root
            onSearch={onSearch}
            className={cn('static w-full lg:relative', classNames?.root)}>
            <Search.Input
                data-testid="search-input"
                value={searchValue}
                onBlur={blurHandler}
                onChange={handleChange}
                placeholder={dictionary?.searchTerm}
                classNames={classNames}
            />
            {showSuggestions ? (
                <Search.List className="max-h-[calc(100dvh-5rem)] w-full overflow-y-auto overflow-x-hidden border-t-0 border-grey-150 bg-white text-xs lg:max-h-[calc(100dvh-7.5rem)] lg:border lg:border-t-0">
                    {autocomplete.data?.queries?.length ? (
                        <section className="border-b py-1">
                            <h6 className="px-9 py-1 uppercase">
                                {dictionary?.searchResults}
                            </h6>
                            {autocomplete.data.queries.map((item) => (
                                <Search.Suggestion
                                    className="px-9 py-1"
                                    key={item}
                                    value={item}>
                                    {getStylizedText(item, searchQuery)}
                                </Search.Suggestion>
                            ))}
                        </section>
                    ) : null}
                    {autocomplete.data?.brands?.length ? (
                        <section className="border-b py-1">
                            <h6 className="px-9 py-1 uppercase">
                                {dictionary?.brands}
                            </h6>
                            {autocomplete.data.brands.map((item) => (
                                <Search.Suggestion
                                    className="px-9 py-1"
                                    type="link"
                                    key={item.Id}
                                    value={item.contentUrl}>
                                    {getStylizedText(
                                        item.contentName,
                                        searchQuery,
                                    )}
                                </Search.Suggestion>
                            ))}
                        </section>
                    ) : null}
                    {autocomplete.data?.categories?.length ? (
                        <section className="border-b py-1">
                            <h6 className="px-9 py-1 uppercase">
                                {dictionary?.categories}
                            </h6>
                            {autocomplete.data.categories.map((item) => (
                                <Search.Suggestion
                                    className="px-9 py-1"
                                    type="link"
                                    key={item.Id}
                                    value={item.contentUrl}>
                                    {getStylizedText(
                                        item.contentName,
                                        searchQuery,
                                    )}
                                </Search.Suggestion>
                            ))}
                        </section>
                    ) : null}
                    {autocomplete.data?.designers?.length ? (
                        <section className="border-b py-1">
                            <h6 className="px-9 py-1 uppercase">
                                {dictionary?.designers}
                            </h6>
                            {autocomplete.data.designers.map((item) => (
                                <Search.Suggestion
                                    className="px-9 py-1"
                                    type="link"
                                    key={item.Id}
                                    value={item.contentUrl}>
                                    {getStylizedText(
                                        item.contentName,
                                        searchQuery,
                                    )}
                                </Search.Suggestion>
                            ))}
                        </section>
                    ) : null}
                    {/* {autocomplete.data?.scopes?.length ? (
                        <section className="border-b py-1">
                            <h6 className="px-9 py-1 uppercase">
                                {dictionary.searchInCategory}
                            </h6>
                            {autocomplete.data.scopes.map((item) => (
                                <Search.Suggestion
                                    className="px-9 py-1"
                                    key={item.category}
                                    value={`${item.query}#Breadcrumbs.Code=NG_${item.category}`}>
                                    <>
                                        {getStylizedText(
                                            item.query,
                                            searchQuery,
                                        )}
                                        &nbsp;in&nbsp;
                                        {commerceNamePairs?.[
                                            `NG_${item.category}`
                                        ] ?? item.category}
                                    </>
                                </Search.Suggestion>
                            ))}
                        </section>
                    ) : null} */}
                </Search.List>
            ) : null}
        </Search.Root>
    );
};

export default HeaderSearch;
