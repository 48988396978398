import { getTrustpilotConfig } from '@/utils/getTrustpilotConfig';

export default function TrustpilotWidget({
    contentfulLocale,
}: {
    contentfulLocale: string;
}) {
    const trustpilotConfig = getTrustpilotConfig(contentfulLocale);

    if (!trustpilotConfig) return null;

    const { dataLocale, businessunitId, href } = trustpilotConfig;

    return (
        <div
            data-locale={dataLocale}
            data-businessunit-id={businessunitId}
            data-style-width="100%"
            data-style-height="28px"
            data-text-color="#000000"
            data-template-id="5406e65db0d04a09e042d5fc"
            className="trustpilot-widget flex items-center border-b border-grey-150 pb-6 pt-7">
            <a href={href} target="_blank" rel="noopener">
                Trustpilot
            </a>
        </div>
    );
}
