'use client';

import Link from '@components/Link';
import { useHeaderContext } from '@context/headerContext';
import { useUspAnimation, uspAnimationVariants } from '@hooks/useUspAnimation';
import { ArrowIcon } from '@ngg/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';

const LeftLinks = () => {
    const { locale, leftLinksCollection } = useHeaderContext();
    const total = useMemo(
        () => leftLinksCollection?.items?.length ?? 0,
        [leftLinksCollection?.items],
    );

    const { setIsAnimationPlaying, currentSlide } = useUspAnimation({
        total,
    });

    if (!leftLinksCollection?.items?.length) return null;

    return (
        <div className="mx-auto flex flex-1 items-center overflow-hidden font-medium max-xs:text-xs">
            <AnimatePresence
                initial={false}
                mode="popLayout"
                presenceAffectsLayout>
                {leftLinksCollection.items.map((item, index) => {
                    if (!item?.title) return null;
                    if (index !== currentSlide) return null;

                    return (
                        <motion.div
                            className="flex grow border-x-transparent text-center max-lg:justify-center lg:border-x-color-base"
                            key={item.sys.id + index}
                            variants={uspAnimationVariants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            onHoverStart={() => setIsAnimationPlaying(false)}
                            onHoverEnd={() => setIsAnimationPlaying(true)}>
                            <Link
                                key={item.sys.id + index}
                                href={
                                    item.externalLink ?? item.internalLink?.slug
                                }
                                locale={locale}
                                className="flex items-center gap-x-1 whitespace-nowrap px-3 hover:underline"
                                target={
                                    item.openInNewTab ? '_blank' : undefined
                                }>
                                {item.title}
                                <ArrowIcon className="h-4 w-4" />
                            </Link>
                        </motion.div>
                    );
                })}
            </AnimatePresence>
        </div>
    );
};

LeftLinks.displayName = 'LeftLinks';

export default LeftLinks;
