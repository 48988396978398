import Link from '@components/Link/Link';
import { ArrowIcon } from '@ngg/icons';

import { useMenuStore } from '@/stores/menuStore';

import type { NavigationLink } from '../types';

export default function SimpleDropdown({
    link,
    locale,
}: {
    link: NavigationLink;
    locale?: string;
}) {
    const { clearActiveWithDelay } = useMenuStore();

    return (
        <ul className="absolute left-2 top-full z-10 flex min-w-32 flex-col gap-2.5 whitespace-nowrap border border-grey-150 bg-white p-4">
            {link?.children?.map(
                (item) =>
                    item && (
                        <li key={item.slug}>
                            <Link
                                onClick={() => clearActiveWithDelay()}
                                locale={locale}
                                className="group flex items-center hover:underline"
                                href={item?.slug}
                                prefetch={false}
                                customPrefetch="onMouseEnter">
                                {item.title}
                                <ArrowIcon className="text-lg opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100" />
                            </Link>
                        </li>
                    ),
            )}
        </ul>
    );
}
