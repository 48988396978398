import type { NavigationLink } from '@components/header/types';
import { useMarket } from '@context/marketContext';

import MobileMenuHighlight from './MobileMenuHighlight';

type MobileMenuHightlightsProps = {
    className?: string;
    link?: NavigationLink;
};

export default function MobileMenuHighlights({
    className,
    link,
}: MobileMenuHightlightsProps) {
    const { locale } = useMarket().state.market;

    return (
        <div className={className}>
            {link?.highlight?.map(
                (item) =>
                    item && (
                        <MobileMenuHighlight
                            key={item.slug}
                            item={item}
                            locale={locale}
                        />
                    ),
            )}
        </div>
    );
}
