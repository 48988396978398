import { SearchIcon } from '@ngg/icons';
import type { FocusEvent, InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef, useCallback } from 'react';

import { cn } from '@/lib/utils';

import { useSearch } from './Context';

type SearchInputProps = InputHTMLAttributes<HTMLInputElement> & {
    icon?: ReactNode;
    classNames?: {
        input?: string;
        icon?: string;
    };
    onIconClick?: (value: string) => void;
};

const Input = forwardRef<HTMLInputElement, SearchInputProps>(
    ({ placeholder, icon, classNames, onIconClick, value, ...props }, ref) => {
        const handleSearch = useCallback(() => {
            if (value && onIconClick) {
                onIconClick(value.toString());
            }
        }, [onIconClick, value]);

        return (
            <label className="relative flex items-center">
                {icon ?? (
                    <SearchIcon
                        onClick={handleSearch}
                        className={cn(
                            'absolute left-2 h-5 w-5 cursor-pointer',
                            classNames?.icon,
                        )}
                    />
                )}

                <input
                    value={value}
                    ref={ref}
                    className={cn(
                        'w-full border border-transparent p-2 pl-9 text-base placeholder:text-sm placeholder:text-grey-300 focus:outline-none lg:text-sm',
                        classNames?.input,
                    )}
                    placeholder={placeholder}
                    {...props}
                />
            </label>
        );
    },
);

const SearchInput = ({
    onBlur: blurHandler = () => null,
    onFocus: focusHandler = () => null,
    value,
    children,
    ...props
}: SearchInputProps) => {
    const { selected, onBlur, onFocus, onKeyDown, onSearch, inputRef } =
        useSearch();

    const handleBlur = (event: FocusEvent<HTMLInputElement>) =>
        onBlur(event).then(blurHandler);
    const handleFocus = (event: FocusEvent<HTMLInputElement>) =>
        onFocus(event).then(focusHandler);

    return (
        <Input
            ref={inputRef}
            value={value}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onIconClick={onSearch}
            onKeyDown={onKeyDown}
            data-active-option={selected}
            {...props}>
            {children}
        </Input>
    );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
