import { getMarketConfigFromLocale } from '@/config/market-configurations';

import { getSearchLocale } from './getSearchLocale';

export function getSearchResultPageUrl({
    locale,
    query,
    category,
}: {
    locale?: string;
    query: string;
    category?: string;
}) {
    const searchLocale = getSearchLocale(locale);
    const marketConfig = getMarketConfigFromLocale(locale);
    const searchPathname = marketConfig.searchPathName;

    const filter = query.split('#');

    if (filter.length > 1) {
        return `${searchLocale}/${searchPathname}?query=${filter[0]}#${filter[1]}`;
    }

    if (query.startsWith('/')) {
        return `${searchLocale}/${query.slice(1)}`;
    }

    const searchParams = new URLSearchParams({
        query,
    });

    if (category) {
        searchParams.set('category', category);
    }

    return `${searchLocale}/${searchPathname}?${searchParams.toString()}`;
}
