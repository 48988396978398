'use client';

import type { NavigationLink } from '@components/header/types';
import { Button, Dialog } from '@components/ui';
import DialogClose from '@components/ui/Dialog/DialogClose';
import { useHeaderContext } from '@context/headerContext';
import type { NavigationDirection } from '@context/mobileMenuContext';
import { MobileMenuContext } from '@context/mobileMenuContext';
import { MenuIcon } from '@ngg/icons';
import { AnimatePresence } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useMenuStore } from '@/stores/menuStore';
import { parseParams } from '@/utils/params';

import { getInitialNavigationTree } from './MobileMenu.hooks';
import MobileMenuBottom from './MobileMenuBottom';
import MobileMenuHeader from './MobileMenuHeader';
import MobileMenuLinks from './MobileMenuLinks';
import MobileMenuTop from './MobileMenuTop';

export default function MobileMenu() {
    const ref = useRef<HTMLDivElement>(null);
    const { navigation: links, dictionary } = useHeaderContext();
    const pathname = usePathname();
    const { slug } = parseParams({ pathname });
    const mobileMenuOpen = useMenuStore((state) => state.mobileMenuOpen);
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);
    const [navigationTree, setNavigationTree] = useState<NavigationLink[]>(
        getInitialNavigationTree({ slug, links }),
    );
    const [navigationDirection, setNavigationDirection] =
        useState<NavigationDirection>('right');

    const context = useMemo(
        () => ({
            navigationTree,
            setNavigationTree,
            navigationDirection,
            setNavigationDirection,
            mobileMenuOpen,
            setMobileMenuOpen,
        }),
        [
            navigationTree,
            setNavigationTree,
            navigationDirection,
            setNavigationDirection,
            mobileMenuOpen,
            setMobileMenuOpen,
        ],
    );

    useEffect(() => {
        ref.current?.parentElement?.scrollTo({ top: 0 });
    }, [navigationTree]);

    return (
        <MobileMenuContext.Provider value={context}>
            <Button
                onClick={() => setMobileMenuOpen(true)}
                className="p-1 lg:hidden">
                <MenuIcon className="text-xl" />
                <span className="sr-only">{dictionary?.menu}</span>
            </Button>
            <AnimatePresence>
                {mobileMenuOpen && (
                    <Dialog.Root
                        static
                        open={mobileMenuOpen}
                        onClose={() => setMobileMenuOpen(false)}
                        className="relative z-50">
                        <Dialog.Overlay />
                        <Dialog.Content
                            ref={ref}
                            className="flex grow flex-col content-stretch text-sm"
                            position="left"
                            open={mobileMenuOpen}
                            onClose={() => setMobileMenuOpen(false)}
                            closeComponent={
                                <DialogClose
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="w-auto px-4 text-xs uppercase tracking-wide underline outline-none">
                                    {dictionary?.close}
                                </DialogClose>
                            }>
                            <Dialog.Header className="border-b">
                                <MobileMenuHeader />
                            </Dialog.Header>
                            <MobileMenuTop />
                            <MobileMenuLinks />
                            <MobileMenuBottom className="mt-auto border-t" />
                        </Dialog.Content>
                    </Dialog.Root>
                )}
            </AnimatePresence>
        </MobileMenuContext.Provider>
    );
}
