import type { NavigationLink } from '@components/header/types';
import Link from '@components/Link';
import { useMarket } from '@context/marketContext';

import { useMenuStore } from '@/stores/menuStore';

type MobileMenuTitleProps = {
    link?: NavigationLink;
    showAllTitle: string;
};

const MobileMenuTitle = ({ link, showAllTitle }: MobileMenuTitleProps) => {
    const { locale } = useMarket().state.market;
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    return (
        <div className="flex w-full items-center justify-between border-b border-grey-150 px-4 py-4">
            <span className="text-sm font-medium capitalize">
                {link?.title}
            </span>
            <Link
                locale={locale}
                href={link?.slug}
                onClick={() => setMobileMenuOpen(false)}>
                {showAllTitle}
            </Link>
        </div>
    );
};

MobileMenuTitle.displayName = 'MobileMenuTitle';

export default MobileMenuTitle;
