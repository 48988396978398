'use client';

import MobileMenu from '@components/MobileMenu/MobileMenu';
import { useHeaderContext } from '@context/headerContext';
import { SearchIcon } from '@ngg/icons';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { cn } from '@/lib/utils';
import { useSearchStore } from '@/stores/searchStore';

import Search from '../search';
import Actions from './Actions';
import Menu from './Menu';

export default function Content({ children }: { children: ReactNode }) {
    const { dictionary, scrollDirection, hasScrolled } = useHeaderContext();
    const mobileSearchOpen = useSearchStore((state) => state.mobileSearchOpen);
    const setMobileSearchOpen = useSearchStore(
        (state) => state.setMobileSearchOpen,
    );
    const hideSearch = useMemo(
        () => hasScrolled && scrollDirection === 'down' && mobileSearchOpen,
        [hasScrolled, scrollDirection, mobileSearchOpen],
    );

    return (
        <div className="lg:pointer-events-none lg:child:pointer-events-auto">
            <div className="relative z-10 bg-white">
                <div className="container relative grid h-11 w-full grid-cols-[4rem,auto,4rem] items-center justify-between bg-white px-3 lg:z-40 lg:h-14 lg:grid-cols-[1fr,auto,1fr]">
                    <div className="flex items-center">
                        <MobileMenu />
                        <button
                            type="button"
                            className={cn(
                                'p-1 transition-opacity duration-100 lg:hidden',
                                !hideSearch && 'pointer-events-none opacity-0',
                            )}
                            onClick={() => {
                                setMobileSearchOpen(!mobileSearchOpen);
                            }}>
                            <SearchIcon className="h-6 w-6" />
                            <span className="sr-only">
                                {dictionary?.search}
                            </span>
                        </button>
                        <Search
                            classNames={{
                                root: 'max-lg:hidden lg:max-w-[220px]',
                                input: 'border-grey-150 focus:border-grey-200',
                            }}
                            dictionary={dictionary}
                        />
                    </div>
                    {children}
                    <div className="flex items-center justify-end">
                        <Actions />
                    </div>
                </div>
            </div>
            <Menu />
            <Search
                classNames={{
                    root: cn(
                        'transition-transform duration-100 lg:hidden',
                        hideSearch && '-translate-y-full',
                    ),
                    input: 'h-10 pl-11 border-t border-b border-color-grey-150 border-x-0 border-grey-150 placeholder:text-sm',
                    icon: 'left-4',
                }}
                dictionary={dictionary}
            />
        </div>
    );
}
