import Button from '@components/Button/Button';
import Link from '@components/Link/Link';
import { ArrowIcon } from '@ngg/icons';

import { useMenuStore } from '@/stores/menuStore';

import type { NavigationLink } from '../types';
import DropdownHighlight from './DropdownHighlight';

export default function BrandDropdown({
    link,
    locale,
}: {
    link: NavigationLink;
    locale?: string;
}) {
    const { clearActiveWithDelay } = useMenuStore();

    if (!link) {
        return null;
    }

    const left = link?.highlight?.at(0);
    const right = link?.highlight?.at(1);

    return (
        <>
            <div className="col-span-2 flex p-4">
                {right && left && (
                    <DropdownHighlight item={left} locale={locale} />
                )}
            </div>
            <ul className="col-span-8 flex border-x border-grey-150">
                {link.children?.map(
                    (child1) =>
                        child1 && (
                            <li
                                key={child1.slug}
                                className="flex flex-1 flex-col border-r p-4 last:border-r-0">
                                <Link
                                    onClick={() => {
                                        (
                                            document.activeElement as HTMLElement
                                        )?.blur();
                                        clearActiveWithDelay();
                                    }}
                                    className="group mb-2 flex items-center font-medium last:mb-0 hover:underline"
                                    locale={locale}
                                    href={child1.slug}
                                    prefetch={false}
                                    customPrefetch="onMouseEnter">
                                    {child1.title}
                                    <ArrowIcon className="text-lg opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100" />
                                </Link>
                                <ul className="w-full flex-grow columns-2 bg-white pb-20 space-y-1">
                                    {child1.children?.map(
                                        (child2) =>
                                            child2 && (
                                                <li key={child2.slug}>
                                                    <Link
                                                        onClick={() => {
                                                            (
                                                                document.activeElement as HTMLElement
                                                            )?.blur();
                                                            clearActiveWithDelay();
                                                        }}
                                                        className="group flex items-center hover:underline"
                                                        locale={locale}
                                                        href={child2.slug}
                                                        prefetch={false}
                                                        customPrefetch="onMouseEnter">
                                                        {child2.title}
                                                        <ArrowIcon className="text-lg opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100" />
                                                    </Link>
                                                </li>
                                            ),
                                    )}
                                </ul>
                                <Button
                                    as={Link}
                                    onClick={() => clearActiveWithDelay()}
                                    href={child1.slug}
                                    locale={locale}
                                    className="btn-outlined w-1/2">
                                    {child1.specialTitle ?? ''}
                                </Button>
                            </li>
                        ),
                )}
            </ul>
            <div className="col-span-2 flex px-4 py-6">
                {(right ?? left) && (
                    <DropdownHighlight item={right ?? left} locale={locale} />
                )}
            </div>
        </>
    );
}
